<template>
  <div>
    <toggleSwitch
      title="Kommentare erlauben"
      :value="comments"
      :callback="setComments"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      comments: this.getComments()
    };
  },
  methods: {
    getComments() {
      if (
        !JSON.parse(JSON.stringify(this.$store.state.privateEvent.unsaved))
          .comments
      ) {
        return false;
      }
      return true;
    },
    setComments() {
      this.comments = !this.comments;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      );
      data.comments = this.comments;
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
};
</script>
